import VueRouter, { RouteConfig } from 'vue-router'
import Vue from 'vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '*',
    name: 'NotFound',
    component: () => import('../views/landing/NotFound.vue')
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import('../views/landing/Error.vue')
  },
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/auth/Login.vue')
  },
  {
    path: '/request-email',
    name: 'RequestEmail',
    component: () => import('../views/auth/RequestEmailForm.vue')
  },
  {
    path: '/password',
    name: 'ResetPassword',
    component: () => import('../views/auth/ResetPassword.vue')
  },
  {
    path: '/success',
    name: 'Success',
    component: () => import('../views/auth/Success.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/auth/Register.vue')
  },
  {
    path: '/logged-in',
    name: 'LoggedIn',
    props: route => ({
      props: route.params as any
    }),
    component: () => import('../views/auth/LoggedIn.vue'),
  },
  {
    path: '/verify',
    name: 'VerifyEmail',
    component: () => import('../views/auth/VerifyEmail.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
